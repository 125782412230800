<template>
  <div class="main">
    <table class="table">
      <thead>
      <td class="header"> OLTU KIYMETLİ MADENLER <span class="data_loading" v-if="data_loading">...</span> </td>
      <td class="header_blue text_align_center"> ALIŞ</td>
      <td class="header_blue text_align_center"> SATIŞ</td>
      <td class="header_white text_align_center"> OKM</td>
      </thead>
      <tr class="data">
        <td class="item">HAS ALTIN</td>
        <td class="item_data text_align_center " v-bind:class="[data_currentlys.filter((item => item.birim === 'ALTIN'))[0].renk.alis_dir]">
          {{ data_currentlys.filter((item => item.birim === 'ALTIN'))[0].alis }}
        </td>
        <td class="item_data text_align_center " v-bind:class="[data_currentlys.filter((item => item.birim === 'ALTIN'))[0].renk.satis_dir]" >  {{ data_currentlys.filter((item => item.birim === 'ALTIN'))[0].satis }}</td>
        <td class="item_data text_align_center blue">{{ data_currentlys.filter((item => item.birim === 'ALTIN'))[0].guncelleme }}</td>
      </tr>
      <tr class="data">
        <td class="item">USD / KG</td>
        <td class="item_data text_align_center " v-bind:class="[data_currentlys.filter((item => item.birim === 'USD/KG'))[0].renk.alis_dir]" > {{ data_currentlys.filter((item => item.birim === 'USD/KG'))[0].alis }}</td>
        <td class="item_data text_align_center " v-bind:class="[data_currentlys.filter((item => item.birim === 'USD/KG'))[0].renk.satis_dir]" >  {{ data_currentlys.filter((item => item.birim === 'USD/KG'))[0].satis }}</td>
        <td class="item_data text_align_center blue">{{ data_currentlys.filter((item => item.birim === 'USD/KG'))[0].guncelleme }}</td>
      </tr>
      <tr class="data">
        <td class="item">EUR / KG</td>
        <td class="item_data text_align_center " v-bind:class="[data_currentlys.filter((item => item.birim === 'EUR/KG'))[0].renk.alis_dir]" > {{ data_currentlys.filter((item => item.birim === 'EUR/KG'))[0].alis }}</td>
        <td class="item_data text_align_center " v-bind:class="[data_currentlys.filter((item => item.birim === 'EUR/KG'))[0].renk.satis_dir]" >  {{ data_currentlys.filter((item => item.birim === 'EUR/KG'))[0].satis }}</td>
        <td class="item_data text_align_center blue">{{ data_currentlys.filter((item => item.birim === 'EUR/KG'))[0].guncelleme }}</td>
      </tr>

      <tr class="data">
        <td class="item">ATA YENİ</td>
        <td class="item_data text_align_center " v-bind:class="[data_currentlys.filter((item => item.birim === 'ATA YENİ'))[0].renk.alis_dir]" > {{ data_currentlys.filter((item => item.birim === 'ATA YENİ'))[0].alis }}</td>
        <td class="item_data text_align_center " v-bind:class="[data_currentlys.filter((item => item.birim === 'ATA YENİ'))[0].renk.satis_dir]" >  {{ data_currentlys.filter((item => item.birim === 'ATA YENİ'))[0].satis }}</td>
        <td class="item_data text_align_center blue">{{ data_currentlys.filter((item => item.birim === 'ATA YENİ'))[0].guncelleme }}</td>
      </tr>
      <tr class="data">
        <td class="item">ATA ESKİ</td>
        <td class="item_data text_align_center " v-bind:class="[data_currentlys.filter((item => item.birim === 'ATA ESKİ'))[0].renk.alis_dir]" > {{ data_currentlys.filter((item => item.birim === 'ATA ESKİ'))[0].alis }}</td>
        <td class="item_data text_align_center " v-bind:class="[data_currentlys.filter((item => item.birim === 'ATA ESKİ'))[0].renk.satis_dir]" >  {{ data_currentlys.filter((item => item.birim === 'ATA ESKİ'))[0].satis }}</td>
        <td class="item_data text_align_center blue">{{ data_currentlys.filter((item => item.birim === 'ATA ESKİ'))[0].guncelleme }}</td>
      </tr>

      <tr class="data">
        <td class="item">ÇEYREK YENİ</td>
        <td class="item_data text_align_center " v-bind:class="[data_currentlys.filter((item => item.birim === 'CEYREK YENI'))[0].renk.alis_dir]" > {{ data_currentlys.filter((item => item.birim === 'CEYREK YENI'))[0].alis }}</td>
        <td class="item_data text_align_center " v-bind:class="[data_currentlys.filter((item => item.birim === 'CEYREK YENI'))[0].renk.satis_dir]" >  {{ data_currentlys.filter((item => item.birim === 'CEYREK YENI'))[0].satis }}</td>
        <td class="item_data text_align_center blue">{{ data_currentlys.filter((item => item.birim === 'CEYREK YENI'))[0].guncelleme }}</td>
      </tr>

      <tr class="data">
        <td class="item">ÇEYREK ESKİ</td>
        <td class="item_data text_align_center " v-bind:class="[data_currentlys.filter((item => item.birim === 'CEYREK ESKI'))[0].renk.alis_dir]" > {{ data_currentlys.filter((item => item.birim === 'CEYREK ESKI'))[0].alis }}</td>
        <td class="item_data text_align_center " v-bind:class="[data_currentlys.filter((item => item.birim === 'CEYREK ESKI'))[0].renk.satis_dir]" >  {{ data_currentlys.filter((item => item.birim === 'CEYREK ESKI'))[0].satis }}</td>
        <td class="item_data text_align_center blue">{{ data_currentlys.filter((item => item.birim === 'CEYREK ESKI'))[0].guncelleme }}</td>
      </tr>

      <tr class="data">
        <td class="item">YARIM YENİ</td>
        <td class="item_data text_align_center " v-bind:class="[data_currentlys.filter((item => item.birim === 'YARIM YENİ'))[0].renk.alis_dir]" > {{ data_currentlys.filter((item => item.birim === 'YARIM YENİ'))[0].alis }}</td>
        <td class="item_data text_align_center " v-bind:class="[data_currentlys.filter((item => item.birim === 'YARIM YENİ'))[0].renk.satis_dir]" >  {{ data_currentlys.filter((item => item.birim === 'YARIM YENİ'))[0].satis }}</td>
        <td class="item_data text_align_center blue">{{ data_currentlys.filter((item => item.birim === 'YARIM YENİ'))[0].guncelleme }}</td>
      </tr>

      <tr class="data">
        <td class="item">YARIM ESKİ</td>
        <td class="item_data text_align_center " v-bind:class="[data_currentlys.filter((item => item.birim === 'YARIM ESKİ'))[0].renk]" > {{ data_currentlys.filter((item => item.birim === 'YARIM ESKİ'))[0].alis }}</td>
        <td class="item_data text_align_center " v-bind:class="[data_currentlys.filter((item => item.birim === 'YARIM ESKİ'))[0].renk]" >  {{ data_currentlys.filter((item => item.birim === 'YARIM ESKİ'))[0].satis }}</td>
        <td class="item_data text_align_center blue">{{ data_currentlys.filter((item => item.birim === 'YARIM ESKİ'))[0].guncelleme }}</td>
      </tr>

      <tr class="data">
        <td class="item">TAM YENİ</td>
        <td class="item_data text_align_center " v-bind:class="[data_currentlys.filter((item => item.birim === 'TAM YENİ'))[0].renk]" > {{ data_currentlys.filter((item => item.birim === 'TAM YENİ'))[0].alis }}</td>
        <td class="item_data text_align_center " v-bind:class="[data_currentlys.filter((item => item.birim === 'TAM YENİ'))[0].renk]" >  {{ data_currentlys.filter((item => item.birim === 'TAM YENİ'))[0].satis }}</td>
        <td class="item_data text_align_center blue">{{ data_currentlys.filter((item => item.birim === 'TAM YENİ'))[0].guncelleme }}</td>
      </tr>

      <tr class="data">
        <td class="item">TAM ESKİ</td>
        <td class="item_data text_align_center " v-bind:class="[data_currentlys.filter((item => item.birim === 'TAM ESKİ'))[0].renk]" > {{ data_currentlys.filter((item => item.birim === 'TAM ESKİ'))[0].alis }}</td>
        <td class="item_data text_align_center " v-bind:class="[data_currentlys.filter((item => item.birim === 'TAM ESKİ'))[0].renk]" >  {{ data_currentlys.filter((item => item.birim === 'TAM ESKİ'))[0].satis }}</td>
        <td class="item_data text_align_center blue">{{ data_currentlys.filter((item => item.birim === 'TAM ESKİ'))[0].guncelleme }}</td>
      </tr>

      <tr class="data">
        <td class="item">GRAM ALTIN</td>
        <td class="item_data text_align_center " v-bind:class="[data_currentlys.filter((item => item.birim === 'GRAM ALTIN'))[0].renk]" > {{ data_currentlys.filter((item => item.birim === 'GRAM ALTIN'))[0].alis }}</td>
        <td class="item_data text_align_center " v-bind:class="[data_currentlys.filter((item => item.birim === 'GRAM ALTIN'))[0].renk]" >  {{ data_currentlys.filter((item => item.birim === 'GRAM ALTIN'))[0].satis }}</td>
        <td class="item_data text_align_center blue">{{ data_currentlys.filter((item => item.birim === 'GRAM ALTIN'))[0].guncelleme }}</td>
      </tr>

    </table>
  </div>
</template>

<script>

import axios from 'axios'
import currency from 'currency.js'

export default {
  name: 'HomeScreen',
  data: function () {
    return {
      data_currentlys : [
        {birim: 'ALTIN', alis: "-", satis: "-", guncelleme: "-",renk:""},
        {birim: 'USD/KG', alis: "-", satis: "-", guncelleme: "-",renk:""},
        {birim: 'EUR/KG', alis: "-", satis: "-", guncelleme: "-",renk:""},
        {birim: 'ATA YENİ', alis: "-", satis: "-", guncelleme: "-",renk:""},
        {birim: 'ATA ESKİ', alis: "-", satis: "-", guncelleme: "-",renk:""},
        {birim: 'CEYREK YENI', alis: "-", satis: "-", guncelleme: "-",renk:""},
        {birim: 'CEYREK ESKI', alis: "-", satis: "-", guncelleme: "-",renk:""},
        {birim: 'YARIM YENİ', alis: "-", satis: "-", guncelleme: "-",renk:""},
        {birim: 'YARIM ESKİ', alis: "-", satis: "-", guncelleme: "-",renk:""},
        {birim: 'TAM ESKİ', alis: "-", satis: "-", guncelleme: "-",renk:""},
        {birim: 'TAM YENİ', alis: "-", satis: "-", guncelleme: "-",renk:""},
        {birim: 'GRAM ALTIN', alis: "-", satis: "-", guncelleme: "-",renk:""}
      ],
      data_loading: [],
    }
  },
  methods: {


    get_data() {
      let vm = this;
      var config = {
        method: 'post',
        url: 'api.php',
      };
      vm.data_loading = true;
      axios(config)
          .then(function (response) {
            console.log(response)
            vm.data_currentlys = [
              {birim: 'ALTIN', alis: vm.formatCurrenty(response.data.data.ALTIN.alis), satis:vm.formatCurrenty(response.data.data.ALTIN.satis), guncelleme: response.data.data.ALTIN.tarih.split(" ")[1],renk:response.data.data.ALTIN.dir },
              {
                birim: 'USD/KG', alis:vm.formatCurrenty( response.data.data.USDKG.alis),
                satis: vm.formatCurrenty(response.data.data.USDKG.satis), guncelleme: response.data.data.USDKG.tarih.split(" ")[1],renk:response.data.data.USDKG.dir
              },
              {
                birim: 'EUR/KG', alis: vm.formatCurrenty(response.data.data.EURKG.alis),
                satis: vm.formatCurrenty(response.data.data.EURKG.satis ), guncelleme: response.data.data.EURKG.tarih.split(" ")[1],renk:response.data.data.EURKG.dir
              },
              {birim: 'ATA YENİ', alis: vm.formatCurrenty(response.data.data.ATA_YENI.alis), satis: vm.formatCurrenty(response.data.data.ATA_YENI.satis), guncelleme: response.data.data.ATA_YENI.tarih.split(" ")[1],renk:response.data.data.ATA_YENI.dir},
              {birim: 'ATA ESKİ', alis: vm.formatCurrenty(response.data.data.ATA_ESKI.alis), satis:vm.formatCurrenty( response.data.data.ATA_ESKI.satis), guncelleme: response.data.data.ATA_ESKI.tarih.split(" ")[1],renk:response.data.data.ATA_ESKI.dir},
              {birim: 'CEYREK YENI', alis: vm.formatCurrenty(response.data.data.CEYREK_YENI.alis), satis:vm.formatCurrenty( response.data.data.CEYREK_YENI.satis), guncelleme: response.data.data.CEYREK_YENI.tarih.split(" ")[1],renk:response.data.data.CEYREK_YENI.dir},
              {birim: 'CEYREK ESKI', alis: vm.formatCurrenty(response.data.data.CEYREK_ESKI.alis), satis: vm.formatCurrenty(response.data.data.CEYREK_ESKI.satis), guncelleme: response.data.data.CEYREK_ESKI.tarih.split(" ")[1],renk:response.data.data.CEYREK_ESKI.dir},
              {birim: 'YARIM YENİ', alis: vm.formatCurrenty(response.data.data.YARIM_YENI.alis), satis: vm.formatCurrenty(response.data.data.YARIM_YENI.satis), guncelleme: response.data.data.YARIM_YENI.tarih.split(" ")[1],renk:response.data.data.YARIM_YENI.dir},
              {birim: 'YARIM ESKİ', alis: vm.formatCurrenty(response.data.data.YARIM_ESKI.alis), satis: vm.formatCurrenty(response.data.data.YARIM_ESKI.satis), guncelleme: response.data.data.YARIM_ESKI.tarih.split(" ")[1],renk:response.data.data.YARIM_ESKI.dir},
              {birim: 'TAM ESKİ', alis: vm.formatCurrenty(response.data.data.TEK_ESKI.alis), satis: vm.formatCurrenty(response.data.data.TEK_ESKI.satis), guncelleme: response.data.data.TEK_ESKI.tarih.split(" ")[1],renk:response.data.data.TEK_ESKI.dir},
              {birim: 'TAM YENİ', alis: vm.formatCurrenty(response.data.data.TEK_YENI.alis), satis: vm.formatCurrenty(response.data.data.TEK_YENI.satis), guncelleme: response.data.data.TEK_YENI.tarih.split(" ")[1],renk:response.data.data.TEK_YENI.dir},
              {birim: 'GRAM ALTIN', alis: vm.formatCurrenty(response.data.data.KULCEALTIN.alis), satis: vm.formatCurrenty(response.data.data.KULCEALTIN.satis), guncelleme: response.data.data.KULCEALTIN.tarih.split(" ")[1],renk:response.data.data.KULCEALTIN.dir}
            ];
            vm.data_loading = false;
            vm.data_currentlys_old= vm.data_currentlys;
          })
          .catch(function () {
            vm.data_currentlys = [
              {birim: 'ALTIN', alis: "-", satis: "-", guncelleme: "-"},
              {birim: 'USD/KG', alis: "-", satis: "-", guncelleme: "-"},
              {birim: 'EUR/KG', alis: "-", satis: "-", guncelleme: "-"},
              {birim: 'ATA YENİ', alis: "-", satis: "-", guncelleme: "-"},
              {birim: 'ATA ESKİ', alis: "-", satis: "-", guncelleme: "-"},
              {birim: 'CEYREK YENI', alis: "-", satis: "-", guncelleme: "-"},
              {birim: 'CEYREK ESKI', alis: "-", satis: "-", guncelleme: "-"},
              {birim: 'YARIM YENİ', alis: "-", satis: "-", guncelleme: "-"},
              {birim: 'YARIM ESKİ', alis: "-", satis: "-", guncelleme: "-"},
              {birim: 'TAM ESKİ', alis: "-", satis: "-", guncelleme: "-"},
              {birim: 'TAM YENİ', alis: "-", satis: "-", guncelleme: "-"},
              {birim: 'GRAM ALTIN', alis: "-", satis: "-", guncelleme: "-"}
            ];
            vm.data_loading = false;
            vm.data_currentlys_old= vm.data_currentlys;
          });

    },
    formatCurrenty(a){
      return currency(a,  { separator: ',' }).format().replace("$","");
    }


  },
  mounted: function () {
    this.get_data();
    let vm = this;
    setInterval(function () {
      vm.get_data();
    }, 1000 * 3);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  background-color: #000000;
  margin: 0;
}

.table {
  width: 100%;
  height: 100%;
}

.text_align_center {
  text-align: right;
}

.table {
  border-collapse: separate;
  border-spacing: 0 4px;
}

.data td {
  background-image: linear-gradient(to bottom, #02398d, #002358 27%, #010811);
}

.item {
  font-family: OpenSans;
  font-size: 3em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.37;
  letter-spacing: normal;
  text-align: left;
  color: #79e9fd;
}

.item_data{
  font-size: 2em;
  width: 200px;
  padding-right: 15px;
}

.header {
  font-family: HelveticaNeue;
  font-size: 5em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #9efe80;
}

.header_blue {
  height: 61px;
  font-family: OpenSans;
  font-size: 45px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #79e9fd;
}

.header_white {
  height: 70px;
  font-family: HelveticaNeue;
  font-size: 60px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;

  color: #ffffff;
}

.blue {
  color: #79e9fd;
}

.green , .up{
  color: #9eff80;
}

.red , .down{
  color: #ff4b5a;
}


.data_loading{
  font-size: 0.1em;
}
</style>
