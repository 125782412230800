<template>
  <div id="app">
    <HomeScreen/>
  </div>
</template>
<script>
import HomeScreen from './components/HomeScreen.vue'


export default {
  title:"OLTU KIYMETLİ MADENLER ",
  name: 'App',
  components: {
    HomeScreen
  }
}
</script>

<style>
#app {
  color: white;
  background-color: #000000;
}

body{
  padding: 8px;
  margin: 0;
  background-color: #000000;
}
</style>
