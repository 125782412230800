import Vue from 'vue'
import App from './App.vue'
import VuePageTitle from 'vue-page-title'

Vue.config.productionTip = false

Vue.use(VuePageTitle, {
  // prefix: 'My App - ',
  //suffix: '- My App '
})

new Vue({
  render: h => h(App),
}).$mount('#app')
